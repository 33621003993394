import React from "react";
import {Helmet} from "react-helmet";

const DefaultLayoutComponent = ({gameType, currentRound, totalRounds, date, children, navbar}) => {

    return (
        <>
            <Helmet>
                {/*Required meta tags*/}
                <meta charSet="utf-8" />
                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/*SEO meta tags*/}
                <title>Capax Imperi</title>
                <meta name="description" content="" />

                {/*Favicons, Touch-icons & more*/}
                {/*<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />*/}
                {/*<link rel="icon" type="image/x-icon" href="/favicon.ico" />*/}
            </Helmet>

            <div className="layout-wrapper bg-marble-light">
                {navbar === true && (
                    <nav className="navbar fixed-top navbar-dark bg-dark">
                        <div className="container-fluid justify-content-between">
                            <span className="navbar-text h5 mb-0">{date}</span>
                            <span className="navbar-text h5 mb-0">
                                {(currentRound != null && totalRounds != null) ? (
                                    <>{gameType === "main" ? "Tour" : "Pastilles"} {gameType === "main" ? currentRound : currentRound - 1} / {totalRounds}</>
                                ) : false}
                            </span>
                        </div>
                    </nav>
                )}
                <main className="d-flex flex-column justify-content-around align-items-center">
                    {children}
                </main>
            </div>
        </>
    )
};

export default DefaultLayoutComponent;
